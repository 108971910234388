<template>
  <div class="home-delivery-page" @scroll="scrolling()">
    <breadcrumbs
        :breadcrumbs="breadcrumbs"
        :scroll="scroll"
        @goBack="back"
    />
    <section class="home-delivery__banner">

      <img
          class="security-audit__banner-img"
          src="@/assets/blog/home-delivery-date/home-delivery-date-top-img.jpg"
          alt="Custom home delivery date & time solution for ecommerce"
          loading="lazy"
          modernize='sizes=["768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
      />
      <h1 class="home-delivery__banner-title">
        CUSTOM HOME DELIVERY DATE & TIME SOLUTION FOR ECOMMERCE
      </h1>
    </section>
    <div class="container blog-home-delivery section_holder">
      <div class="section_title">
        <SidebarBlog :tagFromPost="tags" @searchByTag="goToPath"/>
      </div>
      <div class="section_content">
        <div class="section_content__item">
          <p>
            In the past few years, we’ve already grown accustomed to speedy
            online orders & food delivery, thanks to global pandemics,
            innovative digital technologies, and remodeled consumer
            expectations, and the next industry to embrace these changes is
            Pharmacy/Pharmaceuticals & Healthcare. More & more big eCommerce
            players from different countries consider medicine/medicinal/medical
            industry home delivery as a new way to conveniently make their
            customers' lives easier, especially for those with limited mobility.
          </p>
          <p>
            One of our recent clients is a growing EU-based Pharmacy online
            store. He reached out to us with a request to create a custom
            solution for delivery schedule planning. His Magento 2 website
            offers a wide range of medical products & appliances as a
            round-the-clock service – no wonder the delivery time plays a
            crucial role, and delivery day and hours should be defined even
            before the user starts shopping.
          </p>
          <p>
            Furthermore, other delivery limitations; like over the weekends or
            national holidays, should also be taken into consideration, to
            prevent users from shopping on a certain day or time. Of course,
            numerous custom Magento modules make it possible to add
            delivery/time information to the order, but none of them allow users
            to select and book their delivery day/time before the initial steps
            of the shopping process.
          </p>
          <p>
            So WDevs' solution was to take the existing Delivery Calendar
            extension; since it already gives the users the opportunity to
            choose the delivery day and time from available options (set by the
            admin in the admin panel) on the Checkout step, and to customize
            this extension with the following features:
          </p>
          <ul>
            <li>
              A finely designed & user-friendly popup calendar that works well
              both on Desktop and Mobile devices;
            </li>
            <li>
              A feature that allows users to book the delivery date & time
              BEFORE they proceed to shop;
            </li>
            <li>
              Advanced logic for the time & day of the week, making it easier
              for the users to book each slot according to the client’s store
              business & logistics rules;
            </li>
            <li>
              Delivery date & time may be changed/confirmed on the checkout page
            </li>
          </ul>
          <div class="home-delivery__img">
            <img
                src="@/assets/blog/images/home-delivery.jpg"
                alt="Custom delivery extension preview"
                title="Delivery Calculator"
                loading="lazy"
                modernize='sizes=["480w", "768w", "1200w", "1536w", "1920w", "2400w", "3840w", "original"] imageFormats=["webp", "original"]'
            />
          </div>
          <p>
            Technically, it empowered us to use our company’s 15+ years of
            Magento experience, to develop and implement the best possible
            solution for our client’s needs, as well as goals. The chief tasks
            were as follows:
          </p>
          <ul>
            <li>
              To build the calendar UI to be compatible with the client’s
              Magento theme and its customizations;
            </li>
            <li>
              To develop the mobile version of the calendar with the option to
              slide days up & down on the vertical screen of a mobile device;
            </li>
            <li>
              To add the booked delivery slot information to the user’s session
              so that it will stay reserved until the checkout is completed.
              This data doesn’t exist in either Magento by default, nor in the
              original Delivery Calendar extension, which was used as a base for
              this custom development.
            </li>
          </ul>
          <p>
            The development process took 4 working weeks, including all the
            requisite steps such as: preliminary documentation, design drafts,
            development, deployment on the staging server, QA, review by the
            client, delivery of the customized extension, post-release
            monitoring & support.
          </p>
          <p>
            As a result, our client received a fully customized solution for
            fast delivery management, which allows the brand to adapt its
            eCommerce sales channel to the existing business processes. This new
            feature developed by the WDevs team, helps our client establish a
            high-quality service, as well as saving both time & money for his
            customers.
          </p>
          <p>
            This feature is proving its effectiveness and will be useful for
            various industries where fast logistics are crucial for businesses,
            such as eCommerce, Food & Grocery, Restaurants & Cafes, Retail,
            Flower Delivery, Health & Pharmaceuticals, Medical Testing Labs, Law
            & Accounting Companies, Manufacturing and many more.
          </p>
          <p>
            <span class="link" @click="showModal=true">Get in touch</span> with us to
            find out more about our custom development services for Magento and
            how we can benefit your business.
          </p>
             <ModalContact
    v-if="showModal"
    @close="showModal = false"
    :startProjectModal="false"
    moreTextGetInTouch="Get in touch  with us to
            find out more about our custom development services for Magento and
            how we can benefit your business."
    />
        </div>
        <div>
          <div class="footer-blog-item">
            <div
              class="btn-read-more primary contacts-button small"
              id="show-modal"
              @click="this.$router.push('/blog')"
            >
              <span class="button-content">
                <span class="icon"
                  ><img
                    v-svg-inline
                    :data-src="require('@/assets/icons/rarr-icon.svg')"
                    alt=""
                /></span>
                Read more our stories
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SidebarBlog from "./Sidebar.vue";
import ModalContact from "../ModalContact.vue"
import { mapState } from "vuex";
import Breadcrumbs from "/src/components/Breadcrumbs";

export default {
  name: "BlogHomeDeliveryDate",
  data() {
    return {
      backHistory:history.state.back,
      showModal:false,
      tags:['Healthcare','Delivery','Calendar','Schedule','Delivery date'],
      scroll: false,
      position: null,
      text: `
      Would you like to receive more examples of what we are able to help you achieve?<br />
      Do you still have doubts about your idea and believe that it will be too hard to implement?<br/>
      Would you like us to propose ways of turning your ideas into reality?
      `,
      breadcrumbs: [
        {
          link: '/',
          name: 'Home',
        },
        {
          name: 'Blog',
        },
        {
          name: 'Custom home delivery date & time solution for ecommerce',
        },
      ],
    };
  },
  components: {
    SidebarBlog,
    ModalContact,
    Breadcrumbs,
  },
  computed:{
    ...mapState(['blog'])
  },
  methods: {
    goToPath(tag){
      let post = this.blog.find(item => item.tags.includes(tag))
      this.$router.push(post.img_src)
    },
     back(){
    if(!document.referrer || this.backHistory !== '/blog'){
        Object.defineProperty(document, "referrer", {
          get: function () {
            return `${window.location.origin}/blog`;
          },
        });
        this.$router.push('/blog')
        return
      } else{
        this.$router.go(-1)
      }
    },
    scrolling() {
      const scroll = document.documentElement.scrollTop;
      if (scroll > 50) {
        this.scroll = true;
      } else {
        this.scroll = false;
      }
    },
  },
  created() {
          console.log(history.state.back)
    // history.pushState('blog-home',window.location.href)
    window.history.scrollRestoration = "auto";
    window.addEventListener("scroll", this.scrolling); 
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.scrolling);
  },

};
</script>

<style lang="scss">
@import "../../scss/_variables.scss";
@import "include-media/dist/_include-media.scss";
.home-delivery-header {
  .home-delivery__title {
    padding: 120px 0;
    text-align: end;
    text-transform: uppercase;
    color: $black;
    font-size: 32px;
    line-height: 111%;
    margin: 0;
    @include media(">=tablet") {
      font-size: 40px;
      max-width: 100%;
    }
    @include media(">=tablet-xl") {
      font-size: 56px;
    }
    @include media(">=widescreen") {
      font-size: 72px;
      max-width: 100%;
    }
  }
}

.footer-blog-item {
  display: block;
  padding-top: 20px;
  @include media(">=tablet") {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .home-delivery-tags {
    margin-top: 40px;
    color: $red;
    font-weight: 700;
    font-size: 16px;
    line-height: 28px;
    @include media(">=tablet") {
      margin-top: 0px;
      text-align: center;
    }
    span {
      margin-right: 20px;
      @include media(">=widescreen") {
        margin-right: 40px;
      }
    }
  }
}

.home-delivery-page {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .link{
      color:$red;
      cursor:pointer;
      &:hover{
          text-decoration: underline;
      }
  }
  .btn-read-more {
    .button-content {
      border-radius: 26px;
      padding: 2px 12px 3px;
      background: $red;
      color: $white;
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      cursor: pointer;
      width: max-content;
      background-image: linear-gradient($darkRed, $darkRed);
      background-repeat: no-repeat;
      transition: background-size 0s, color 0s;
      background-size: 0% 100%;
      @include media(">=tablet-xl") {
        padding: 6px 24px 8px;
      }
      &:hover {
        background-size: 100% 100%;
        color: $white;
      }
    }
    .icon {
      padding-top: 6px;
      margin-right: 15px;
      transform: rotateY(180deg);
    }
    a {
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  .go-back-to-read {
    padding: 16px 0px;
    border-bottom-left-radius: 26px 26px;
    border-bottom-right-radius: 26px 26px;
    position: fixed;
    background: $white;
    z-index: 50;
    width: 100vw;
    transition: all 0.3s;
    @include media(">=tablet-xl") {
      padding: 16px 0px;
    }
    &.scrolling {
      padding: 5px 0px;
      border-bottom: 1px solid $red;
      @include media(">=tablet-xl") {
        padding: 5px 0px;
      }
    }
  }
}
.blog-home-delivery {
  &.section_holder {
    display: flex;
    flex-direction: column-reverse;
    @include media(">=tablet-xl") {
      flex-direction: row;
    }
  }
  .section_title {
    margin-right: 0px;
    width: 100%;
    @include media(">=tablet-xl") {
      margin-right: 43px;
      width: 35%;
    }
  }
  .section_content {
    margin-bottom: 60px;
    display: block;
    margin-left: 0px;
    width: 100%;
    a {
      color: $red;
    }
    &__item {
      margin-bottom: 40px;
      @include media(">=tablet") {
        margin-bottom: 60px;
      }
      @include media(">=desktop") {
        margin-bottom: 120px;
      }
    }
    @include media(">=tablet-xl") {
      margin-left: 37px;
      width: 65%;
      margin-bottom: 0px;
    }
  }
}

.home-delivery__banner {
  height: 300px;
  position: relative;
  margin-bottom: 60px;
  @include media(">=tablet") {
    height: 500px;
  }
  @include media(">=desktop") {
    height: 600px;
    margin-bottom: 120px;
  }
  &-img,
  picture {
    display: block;
    height: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
.home-delivery__banner-title {
  text-transform: uppercase;
  color: $white;
  font-size: 32px;
  line-height: 111%;
  position: absolute;
  bottom: 20px;
  left: 16px;
  max-width: calc(100% - 32px);
  margin: 0;
  @include media(">=tablet") {
    bottom: 40px;
    font-size: 40px;
    left: 40px;
    max-width: calc(100% - 80px);
  }
  @include media(">=tablet-xl") {
    bottom: 80px;
    left: 40px;
    font-size: 56px;
    max-width: calc(100% - 80px);
  }
  @include media(">=widescreen") {
    bottom: 80px;
    left: 80px;
    font-size: 72px;
    max-width: 1150px;
  }
}
.home-delivery__first,
.home-delivery__innovations,
.home-delivery__process,
.home-delivery__customer {
  p {
    margin-bottom: 0;
  }
  ul {
    padding-left: 30px;
    margin-bottom: 0px;
    ul {
      margin-top: 32px;
      li {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    li {
      margin-bottom: 32px;
    }
  }

  a {
    color: $red;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .section_content {
    flex-direction: column;
    align-items: flex-start;
  }
  .section_title {
    margin-bottom: 30px;
    @include media(">=tablet") {
      margin-bottom: 60px;
    }
  }
}
.home-delivery__img,
picture {
  margin: 0 0 40px;
  display: block;
}
.home-delivery__customer {
  em {
    display: block;
    margin-top: 32px;
  }
  .button {
    margin-top: 40px;
  }
}
</style>
